import { get, post, put } from '@/lib/request'
import { createChatMessage } from '@/models/chat-message.js'

export default {
  async getChat (_, payload) {
    const output = await get(`chats/${payload.chatId}`, payload)

    const meta = output.response.meta
    const response = output.response.map((message) => createChatMessage(message))
    response.meta = meta

    return response
  },

  async newMessage(_, payload) {
    const output = await post(`chats/${payload.chatId}/messages`, payload)
    return createChatMessage(output.response)
  },

  async getUnreadMessages({ commit }) {
    const output = await get('/chats/unread-messages')

    commit('SET_UNREAD_MESSAGES_COUNT', output.response.meta?.unreadMessagesCount || 0)

    return output.response.meta
  },

  async getChats(_, payload) {
    const output = await get('chats', payload)

    const meta = output.response.meta
    const response = output.response.map((chat) => ({
      ...chat,
      lastMessage: chat.lastMessage && createChatMessage(chat.lastMessage)
    }))
    response.meta = meta

    return response
  },

  readMessages(_, payload) {
    return put(`/chats/${payload.chatId}/read`)
  }
}
