import snakeCaseKeys from 'snakecase-keys'

const pushGtmEvent = ({ userId, eventAction, unique = false }) => {
  if (!unique || !checkGtmAlreadySent(eventAction)) {
    const message = {
      event: 'central_candidates_frontend_app_event',
      ...snakeCaseKeys({
        eventAction
      })
    }

    if (userId) message.uidValue = userId

    if (unique) setGtmItem({ [eventAction]: true })

    window.dataLayer?.push(message)
  }
}

const checkGtmAlreadySent = (key) => {
  const currentGtm = getGtmStorage()
  return !!(currentGtm && currentGtm[key])
}

const getGtmStorage = () => {
  return JSON.parse(localStorage.getItem('gtm'))
}

const setGtmItem = (gtm) => {
  const currentGtm = getGtmStorage()
  const newGtm = { ...currentGtm, ...gtm }

  return localStorage.setItem('gtm', JSON.stringify(newGtm))
}

const removeGtmItem = (key) => {
  const currentGtm = getGtmStorage()
  if (currentGtm && currentGtm[key]) delete currentGtm[key]

  return localStorage.setItem('gtm', JSON.stringify(currentGtm))
}

const clearGtmStorage = () => {
  return localStorage.removeItem('gtm')
}

export {
  pushGtmEvent,
  checkGtmAlreadySent,
  getGtmStorage,
  setGtmItem,
  removeGtmItem,
  clearGtmStorage
}
