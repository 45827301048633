import i18n from '@/i18n'
import Toasts from '@/constants/pending-actions/toasts'

const TOAST_TRANSLATIONS = () => [
  {
    value: 'marketplaceUninvitable',
    title: i18n.global.t('pendingActions.toasts.marketplaceUninvitable.title'),
    content: 'pendingActions.toasts.marketplaceUninvitable.content',
    contentEmphasis: i18n.global.t('pendingActions.toasts.marketplaceUninvitable.contentEmphasis'),
    action: i18n.global.t('pendingActions.toasts.marketplaceUninvitable.action'),
  },
  {
    value: 'marketplaceVisibility',
    title: i18n.global.t('pendingActions.toasts.marketplaceVisibility.title'),
    content: 'pendingActions.toasts.marketplaceVisibility.content',
    contentEmphasis: i18n.global.t('pendingActions.toasts.marketplaceVisibility.contentEmphasis'),
    action: i18n.global.t('pendingActions.toasts.marketplaceVisibility.action'),
  }
]

export const createToast = toast => {
  const currentToast = Toasts[toast.id]

  if (!currentToast) return

  const currentToastTranslation = TOAST_TRANSLATIONS().find(toast =>
    toast.value === currentToast.path
  )

  return {
    id: toast.id,
    title: currentToastTranslation.title,
    content: currentToastTranslation.content,
    contentEmphasis: currentToastTranslation.contentEmphasis,
    actionText: currentToastTranslation.action,
    actionUrl: currentToast.externalUrl,
    actionDispatch: currentToast.dispatch,
    icon: currentToast.icon
  }
}

export const createToasts = toasts => toasts.map(createToast).filter(toast => toast)
