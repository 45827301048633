export default {
  positions: {
    applying: false,
    count: 0,
    current: [],
    currentPage: 0,
    loading: false,
    loadingMore: false,
    totalPages: 0
  },
  appliedPositions: {
    count: 0,
    current: [],
    currentPage: 0,
    loading: false,
    totalPages: 0
  },
  recommendedPositions: {
    count: 0,
    current: [],
    currentPage: 0,
    loading: false,
    totalPages: 0
  }
}
