export const createPosition = (position) => ({
  id: position.id,
  alreadyApplied: position.alreadyApplied,
  benefits: position.benefits,
  careerId: position.careerId,
  client: position.client,
  createdAt: position.createdAt,
  description: position.description,
  descriptionRich: position.descriptionRich,
  experienceTime: position.experienceTime,
  locationName: position.locationName,
  maxSalary: position.maxSalary,
  minSalary: position.minSalary,
  positionLanguages: position.positionLanguages,
  positionSkills: position.positionSkills,
  questionsFormId: position.questionsFormId,
  remote: position.remote,
  salaryCurrency: position.salaryCurrency,
  skills: position.skills,
  status: position.status,
  targetMarket: position.targetMarket,
  title: position.title,
  uuid: position.uuid
})
