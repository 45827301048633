import { get, post } from '@/lib/request'
import { pushGtmEvent } from '@/lib/google-tags-manager'

export default {
  async getPositions ({ commit }, options = {}) {
    const { filters, page, notApplied } = { filters: {}, notApplied: false, page: 1, ...options }

    if (page === 1) commit('SET_LOADING_POSITIONS', true)
    else commit('SET_LOADING_MORE_POSITIONS', true)

    const { response } = await get(
      '/international/positions', { notApplied, page, ...filters }
    )

    if (page === 1) commit('SET_POSITIONS', response)
    else commit('ADD_POSITIONS', response)

    commit('SET_POSITIONS_COUNT', response.meta.totalEntries)
    commit('SET_POSITIONS_CURRENT_PAGE', response.meta.page)
    commit('SET_POSITIONS_TOTAL_PAGES', response.meta.totalPages)

    commit('SET_LOADING_POSITIONS', false)
    commit('SET_LOADING_MORE_POSITIONS', false)
  },

  async getAppliedPositions ({ commit }, options = { page: 1 }) {
    commit('SET_LOADING_APPLIED_POSITIONS', true)

    const { response } = await get('/international/positions/applied', options)

    if (options.page === 1) commit('SET_APPLIED_POSITIONS', response)
    else commit('ADD_APPLIED_POSITIONS', response)

    commit('SET_APPLIED_POSITIONS_PAGINATION', response.meta)

    commit('SET_LOADING_APPLIED_POSITIONS', false)
  },

  async getPosition ({ commit, getters }, id) {
    if (getters.getPosition(id)) return
    commit('SET_LOADING_POSITIONS', true)

    const { response } = await get(`/international/positions/${id}`)

    commit('ADD_POSITIONS', [response])

    commit('SET_LOADING_POSITIONS', false)
  },

  async applyToPosition ({ commit }, positionId) {
    commit('SET_APPLYING_TO_POSITION', true)

    await post(`/international/positions/${positionId}/apply`)

    commit('UPDATE_POSITION_APPLY', positionId)
    commit('SET_APPLYING_TO_POSITION', false)
  },

  async sendResumeScreeningEligibilityToGtm () {
    const { response } = await get('/international/eligibility/resume_screening')

    if (response.eligible) {
      pushGtmEvent({ eventAction: 'fc_sign_up_itl_eligible_rs', unique: true })
    }
  },

  async getRecommendedPositions ({ commit }, options = { page: 1 }) {
    commit('SET_LOADING_RECOMMENDED_POSITIONS', true)

    const { response } = await get('/international/positions/recommended', options)

    if (options.page === 1) commit('SET_RECOMMENDED_POSITIONS', response)
    else commit('ADD_RECOMMENDED_POSITIONS', response)

    commit('SET_RECOMMENDED_POSITIONS_PAGINATION', response.meta)

    commit('SET_LOADING_RECOMMENDED_POSITIONS', false)
  }
}
