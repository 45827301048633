import i18n from '@/i18n'

export const createChatMessage = (messageAttribute) => {
  return {
    ...messageAttribute,

    get displayCreatedAt() {
      return i18n.global.d(messageAttribute.createdAt, 'full-date-time')
    },

    get isAuthor() {
      return this.senderType === 'candidate'
    },

    get isNew() {
      return !this.isAuthor && this.readAt === null
    }
  }
}
