import router from './router'
import store from './store'
import i18n from './i18n'
import maska from 'maska'
import { createApp } from 'vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import App from './App.vue'

const app = createApp(App)
const device = createDeviceDetector()

app.use(i18n)
app.use(store)
app.use(router)
app.use(device)
app.use(maska)

app.mount('#app')
