const requestConfig = (accessToken, customOptions) => {
  return {
    baseURL: process.env.VUE_APP_API_URL ||
    'https://api.careers.dev.revelo.io/central_candidates_frontend',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      ...customOptions.headers
    }
  }
}

export default requestConfig
