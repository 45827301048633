import { get, post } from '@/lib/request'
import { createApplication } from '@/models/international/application.js'

export default {
  async getApplication ({ commit }, id) {
    const { response } = await get(`/international/applications/${id}`)

    commit('SET_APPLICATION', createApplication(response))
  },

  async rejectApplication ({ commit }, params) {
    const { response } = await post(`/international/applications/${params.id}/reject`, params)

    commit('SET_APPLICATION', response)
  }
}
