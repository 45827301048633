import { get } from '@/lib/request'

export default {
  async getFocuses ({ commit }) {
    commit('SET_LOADING', true)

    const { response } = await get('/focuses')
    commit('SET_FOCUSES', response)

    commit('SET_LOADING', false)
  }
}
