import { Deserializer } from 'jsonapi-serializer'
import camelCaseKeys from 'camelcase-keys'

const JSONAPIDeserializer = new Deserializer({ keyForAttribute: 'camelCase' })

export default async (payload) => {
  if (!payload) { return undefined }

  let deserializedData

  try {
    deserializedData = await JSONAPIDeserializer.deserialize(payload)
  } catch (error) {
    deserializedData = camelCaseKeys(payload, { deep: true })
  }

  if (payload.meta) deserializedData.meta = camelCaseKeys(payload.meta)

  return deserializedData
}
