import externalUrls from '@/constants/external-urls'

export default [
  {
    path: 'redirects',
    children: [
      {
        path: 'home-international',
        name: 'PublicHomeInternational',
        component: () => import('@/views/Public.vue'),
        beforeEnter: () => { window.location.href = externalUrls.homeInternational }
      }
    ]
  }
]
