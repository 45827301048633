export default {
  ADD_POSITIONS (state, positions) {
    state.positions.current = [...state.positions.current, ...positions]
  },

  SET_POSITIONS (state, positions) {
    state.positions.current = [...positions]
  },

  ADD_APPLIED_POSITIONS (state, positions) {
    state.appliedPositions.current = [...state.appliedPositions.current, ...positions]
  },

  SET_APPLIED_POSITIONS (state, positions) {
    state.appliedPositions.current = [...positions]
  },

  SET_APPLIED_POSITIONS_PAGINATION (state, { page, totalPages, totalEntries }) {
    state.appliedPositions.count = totalEntries
    state.appliedPositions.currentPage = page
    state.appliedPositions.totalPages = totalPages
  },

  SET_LOADING_APPLIED_POSITIONS (state, loading) {
    state.appliedPositions.loading = loading
  },

  SET_LOADING_POSITIONS (state, loading) {
    state.positions.loading = loading
  },

  SET_LOADING_MORE_POSITIONS (state, loading) {
    state.positions.loadingMore = loading
  },

  SET_POSITIONS_COUNT (state, count) {
    state.positions.count = count
  },

  SET_POSITIONS_CURRENT_PAGE (state, page) {
    state.positions.currentPage = page
  },

  SET_POSITIONS_TOTAL_PAGES (state, page) {
    state.positions.totalPages = page
  },

  SET_APPLYING_TO_POSITION (state, applying) {
    state.positions.applying = applying
  },

  UPDATE_POSITION_APPLY (state, positionId) {
    state.positions.current = state.positions.current.map((position) => (
      position.id === positionId ? { ...position, alreadyApplied: true } : position
    ))
  },

  SET_LOADING_RECOMMENDED_POSITIONS (state, loading) {
    state.recommendedPositions.loading = loading
  },

  SET_RECOMMENDED_POSITIONS (state, positions) {
    state.recommendedPositions.current = [...positions]
  },

  ADD_RECOMMENDED_POSITIONS (state, positions) {
    state.recommendedPositions.current = [...state.recommendedPositions.current, ...positions]
  },

  SET_RECOMMENDED_POSITIONS_PAGINATION (state, { page, totalPages, totalEntries }) {
    state.recommendedPositions.count = totalEntries
    state.recommendedPositions.currentPage = page
    state.recommendedPositions.totalPages = totalPages
  }
}
