import { FOCUS_OTHER_SLUG } from '@/constants/focus'
import defaultAvatar from '@/assets/profile/avatar.svg'

export default {
  firstName: (state) => (state.current.name || '').split(' ')[0],

  primarySkills: (state) => (state.current.skills || [])
    .filter((skill) => skill.isPrimary === undefined || skill.isPrimary),

  secondarySkills: (state) => (state.current.skills || [])
    .filter((skill) => skill.isPrimary !== undefined && !skill.isPrimary),

  canApplyToInternational: (state) =>
    state.eligibleForInternational && state.current.internationalProfileCompleted,

  internationalProfileCompleted: (state) =>
    state.current.internationalProfileCompleted,

  eligibleForInternational: (state) => state.eligibleForInternational,

  canAccessAssessments: (state) =>
    state.eligibleForInternational && state.current.internationalProfileCompleted,

  mustAnswerTechAssessment: (state) => !state.current.assessmentResponded,

  livesInBrazil: (state) => state.current.location.country === 'Brazil',

  uneligibleForMarketplace: (state) => {
    return !(state.current.internationalProfileCompleted && state.current.marketplace.invitable)
  },

  selectedFocusOther: (state) => state.current.focus.slug === FOCUS_OTHER_SLUG,

  latamPretensionSalary: (state) => {
    return (state.current.salaries || [])
      .sort((salaryA, salaryB) => new Date(salaryB.updatedAt) - new Date(salaryA.updatedAt) )
      .find((salary) =>
        salary.periodicity === 'monthly' &&
        salary.kind === 'pretension'
      )
  },

  currentSalary: (state) => {
    return (state.current.salaries || [])
      .sort((salaryA, salaryB) => new Date(salaryB.updatedAt) - new Date(salaryA.updatedAt) )
      .find((salary) =>
        salary.kind === 'current'
      )
  },

  returningFromOldFunnel: (state) => {
    return !state.current.signupCompleted && state.current.marketplace?.hasAccount
  },

  lastSkillsUpdate: (state) => {
    const dates = state.current.skills.map(skill => new Date(skill.updatedAt))

    return Math.max(...dates)
  },

  completedJobSkills: (state) => {
    const hasSkills = ({ skills }) => skills?.length

    const { jobExperiences } = state.current

    return jobExperiences.length && jobExperiences.every(hasSkills)
  },

  lastExperienceTimeUpdate: (state) => {
    return new Date(state.current.filledAt)
  },

  avatarUrl: (state) => {
    return state.current.photoUrl || defaultAvatar
  }
}
