export default {
  SET_CARDS (state, cards) {
    state.cards = cards
  },

  SET_TOASTS (state, toasts) {
    state.toasts = toasts
  },

  SET_LOADING (state, loading) {
    state.loading = loading
  }
}
