<template>
  <router-view data-test="router-view" />
</template>

<script>
export default {
  name: 'App',

  created () {
    document.title = this.$t('title')
  }
}
</script>
