import { createStore } from 'vuex'
import applications from './applications'
import assessments from './assessments'
import blockedCompanies from './blocked-companies'
import browser from './browser'
import candidate from './candidate'
import focuses from './focuses'
import international from './international'
import internationalApplications from './international/applications'
import internationalInvites from './international/invites'
import invites from './invites'
import latamApplicants from './latam-applicants'
import links from './links'
import notifications from './notifications'
import parsedLinkedin from './parsed-linkedin'
import pendingActions from './pending-actions'
import skills from './skills'
import chat from './chat'

export const modules = {
  applications,
  assessments,
  blockedCompanies,
  browser,
  candidate,
  focuses,
  international,
  internationalApplications,
  internationalInvites,
  invites,
  links,
  latamApplicants,
  notifications,
  parsedLinkedin,
  pendingActions,
  skills,
  chat
}

const store = createStore({ modules })

export default store
