export const createApplicant = (applicant) => ({
  id: applicant.id,
  chatId: applicant.chatId,
  company: applicant.company,
  globalCandidateId: applicant.globalCandidateId,
  interviews: applicant.interviews,
  invite: applicant.invite,
  negotiatedSalary: applicant.negotiatedSalary,
  position: applicant.position,
  state: applicant.state
})
