import { get, post } from '@/lib/request'

export default {
  async getBlockedCompanies ({ commit }) {
    commit('SET_LOADING', true)

    const { response } = await get('/blocked_companies')

    commit('SET_BLOCKEDCOMPANIES', response)

    commit('SET_LOADING', false)
  },

  async saveBlockedCompanies(_, blockedCompanies) {
    await post('/blocked_companies/upsert', {
      'blocked_companies': blockedCompanies
    })
  }
}
