import edit from './profile/edit'
import educations from './profile/educations'
import jobExperiences from './profile/job-experiences'

export default [
  {
    path: 'profile',
    component: () => import('@/views/Profile/Profile.vue'),
    children: [
      {
        path: '',
        name: 'ProfileHome',
        component: () => import('@/views/Profile/ProfileHome.vue'),
        meta: { viewingProfile: true }
      },
      ...edit,
      ...jobExperiences,
      ...educations
    ]
  }
]
