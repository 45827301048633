// TODO: Use new show screen for international applications

export default [
  {
    path: '/international/applications/:id',
    component: () => import('@/views/TalentApplications/Application.vue'),
    children: [
      {
        name: 'ApplicationInfo',
        path: '',
        component: () => import('@/views/TalentApplications/ApplicationInfo.vue')
      }
    ]
  }
]
