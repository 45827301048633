export default [
  {
    path: 'signup',
    name: 'Signup',
    redirect: { name: 'SignupContainer' },
    component: () => import('@/views/Signup/Signup.vue'),
    children: [
      {
        path: 'welcome',
        name: 'SignupWelcome',
        component: () => import('@/views/Signup/SignupWelcome.vue')
      },
      {
        path: 'welcome-back',
        name: 'SignupWelcomeBack',
        component: () => import('@/views/Signup/SignupWelcomeBack.vue')
      },
      {
        path: 'waitlist',
        name: 'SignupEligibilityWaitlist',
        component: () => import('@/views/Signup/SignupEligibilityWaitlist.vue')
      },
      {
        path: ':currentStep',
        name: 'SignupContainer',
        props: true,
        component: () => import('@/views/Signup/SignupContainer.vue')
      },
      {
        path: 'experiences/create-job',
        name: 'SignupJobExperienceCreate',
        component: () => import(
          '@/views/Signup/SignupExperiences/JobExperienceEdit/SignupJobExperienceEdit.vue'
        )
      },
      {
        path: 'experiences/edit-job/:jobExperienceIndex',
        name: 'SignupJobExperienceEdit',
        component: () => import(
          '@/views/Signup/SignupExperiences/JobExperienceEdit/SignupJobExperienceEdit.vue'
        )
      },
      {
        path: 'experiences/create-education',
        name: 'SignupEducationCreate',
        component: () => import(
          '@/views/Signup/SignupExperiences/EducationEdit/SignupEducationEdit.vue'
        )
      },
      {
        path: 'experiences/edit-education/:educationIndex',
        name: 'SignupEducationEdit',
        component: () => import(
          '@/views/Signup/SignupExperiences/EducationEdit/SignupEducationEdit.vue'
        )
      }
    ]
  }
]
