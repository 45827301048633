import { createCandidate } from '@/models/candidate'

export default {
  SET_CURRENT (state, { candidate, marketplace }) {
    state.current = createCandidate(candidate, marketplace)
  },

  SET_ELIGIBLE_FOR_INTERNATIONAL (state, eligible) {
    state.eligibleForInternational = eligible
  },

  SET_JOB_EXPERIENCES (state, jobExperiences) {
    state.jobExperiences = jobExperiences
  },

  ADD_JOB_EXPERIENCE (state, jobExperience) {
    state.jobExperiences = [
      ...(state.jobExperiences || []),
      jobExperience
    ]
  },

  UPDATE_JOB_EXPERIENCE (state, { jobExperience, index }) {
    const editedExperienceIndex = parseInt(index, 10)
    state.jobExperiences = state.jobExperiences.map((currentJobExperience, currentIndex) => {
      if (currentIndex === editedExperienceIndex) return jobExperience

      return currentJobExperience
    })
  },

  SET_EDUCATIONS (state, educations) {
    state.educations = educations
  },

  ADD_EDUCATION (state, education) {
    state.educations = [
      ...(state.educations || []),
      education
    ]
  },

  UPDATE_EDUCATION (state, { education, index }) {
    const editedEducationIndex = parseInt(index, 10)
    state.educations = state.educations.map((currentEducation, currentIndex) => {
      if (currentIndex === editedEducationIndex) return education

      return currentEducation
    })
  },

  ADD_SALARY (state, salary) {
    state.current.salaries = [
      ...(state.current.salaries || []),
      salary
    ]
  },

  UPDATE_SALARY (state, { index, salary }) {
    state.current.salaries = state.current.salaries.map((currentSalary, currentIndex) => {
      if (currentIndex !== index) return currentSalary

      return salary
    })
  },

  SET_IS_CHOOSING_TO_BE_VISIBLE (state, isChoosing) {
    state.isChoosingToBeVisible = isChoosing
  },

  PUSH_UPDATE_TO_QUEUE (state, attributes) {
    state.updateQueue.push(attributes)
  },

  REMOVE_UPDATES_FROM_QUEUE (state, count) {
    state.updateQueue.splice(0, count)
  },

  SET_LATAM_PENDING_ACTIONS (state, latamPendingActions) {
    state.latamPendingActions = latamPendingActions
  }
}
