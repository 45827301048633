export default {
  international_tech_assessment: {
    icon: 'bolt',
    path: 'internationalTechAssessment',
    route: () => ({
      name: 'AssessmentsList',
      query: {
        source: 'next-steps',
        type: 'assessment'
      }
    })
  },
  international_assessment_unbundled: {
    icon: 'bolt',
    path: 'internationalAssessmentUnbundled',
    route: () => ({
      name: 'AssessmentsList',
      query: {
        source: 'next-steps',
        type: 'assessment_unbundled'
      }
    })
  },
  job_skills: {
    icon: 'file-copy-alt',
    path: 'jobSkills',
    route: () => ({ name: 'ProfileProfessionalExperiences' })
  },
  portfolio: {
    icon: 'file-copy-alt',
    path: 'portfolio',
    route: () => ({ name: 'ProfileEditPortfolios' })
  },
  profile_photo: {
    icon: 'user-square',
    path: 'profilePhoto',
    route: () => ({ name: 'ProfileEditPhoto' })
  },
  screening: {
    icon: 'microphone',
    path: 'screening',
    route: () => ({
      name: 'AssessmentsList',
      query: {
        selected: 'pre-screening',
        source: 'next-steps',
        type: 'screening'
      }
    })
  },
  secondary_skills: {
    icon: 'brush-alt',
    path: 'secondarySkills',
    route: () => ({ name: 'ProfileEditSkills' })
  },
  secondary_skills_experience_time: {
    icon: 'brush-alt',
    path: 'secondarySkillsExperienceTime',
    route: () => ({ name: 'ProfileEditSkills' })
  }
}
