import { createSkill } from '@/models/skill'

export default {
  SET_SKILLS (state, skills) {
    const joinedSkills = [...skills.map(createSkill), ...state.all]
    const uniqueIds = Array.from(new Set(joinedSkills.map(({ id }) => id)))

    state.all = uniqueIds.map((id) => joinedSkills.find((skill) => skill.id === id))
  }
}
