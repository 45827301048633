import { get } from '@/lib/request'

export default {
  async getParsedLinkedin ({ commit }, profileLinkedinUrl) {
    const payload = { profileUrl: profileLinkedinUrl }
    const { response, status } = await get('/signup/linkedin_parse/fetch', payload)

    commit('SET_PARSED_LINKEDIN', response)

    return {
      parsedLinkedin: response,
      status
    }
  },

  clearLinkedinEducations ({ commit }) {
    commit('CLEAR_LINKEDIN_EDUCATIONS')
  }
}
