import { get } from '@/lib/request'

export default {
  async indexApplications ({ commit }, payload) {
    const { response } = await get('/applications', payload)

    commit('SET_APPLICATIONS', { [payload.section]: response })
  },
  async fetchInProgressApplications ({ commit }, payload) {
    const { response: pendingApplications } = await get(
      '/applications', { ...payload, section: 'pending' }
    )

    const { response: activeApplications } = await get(
      '/applications', { ...payload, section: 'active' }
    )

    commit('SET_APPLICATIONS', {
      pending: pendingApplications, active: activeApplications
    })
  }
}
