import Axios from 'axios'
import deserialize from './request-deserializer'
import paramsSerializer from './params-serializer'
import requestConfig from '@/setup/request'
import i18n from '@/i18n'
import snakeCaseKeys from 'snakecase-keys'
import { errorHandler } from './request-error-handler'
import { getToken } from '@revelotech/frontend-core/keycloak'

const REQUEST_TIMEOUT_IN_SECONDS = 30

async function request (method, url, params = {}, customOptions = {}) {
  const keycloakToken = getToken()

  const config = {
    url,
    method,
    paramsSerializer,
    timeout: REQUEST_TIMEOUT_IN_SECONDS * 1000,
    ...requestConfig(keycloakToken, customOptions)
  }

  if (config.headers['Content-Type'] === 'multipart/form-data') {
    params.set('locale', i18n.global.locale.value)
  } else {
    params = snakeCaseKeys({
      ...params,
      locale: i18n.global.locale.value
    })
  }

  if (['get', 'delete'].includes(method)) {
    config.params = params
  } else {
    config.data = params
  }

  try {
    const response = await Axios.request(config)

    return {
      response: await deserialize(response.data),
      status: response.status
    }
  } catch (err) {
    throw errorHandler(err)
  }
}

export const get = (url, params, customOptions) => request('get', url, params, customOptions)
export const post = (url, data, customOptions) => request('post', url, data, customOptions)
export const put = (url, data, customOptions) => request('put', url, data, customOptions)
export const patch = (url, data, customOptions) => request('patch', url, data, customOptions)
export const del = (url, params, customOptions) => request('delete', url, params, customOptions)
