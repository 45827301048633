export default [
  {
    path: '/latam/applicants/:id',
    component: () => import('@/views/LatamApplicant/Applicant.vue'),
    children: [
      {
        name: 'ApplicantInfo',
        path: '',
        component: () => import('@/views/LatamApplicant/ApplicantInfo.vue')
      },
      {
        name: 'ApplicantSchedule',
        path: 'interviews/:interviewId/schedule',
        component: () => import('@/views/LatamApplicant/ApplicantSchedule.vue')
      },
      {
        name: 'ApplicantChat',
        path: 'chat',
        component: () => import('@/views/LatamApplicant/ApplicantChat.vue'),
        meta: { fullHeight: true }
      }
    ]
  }
]
