import { get } from '@/lib/request'
import { createCards } from '@/models/pending-actions/card'
import { createToasts } from '@/models/pending-actions/toast'

export default {
  async getPendingActions ({ commit }) {
    commit('SET_LOADING', true)

    const { response } = await get('/candidates/pending_actions')

    commit('SET_CARDS', createCards(response.cards))
    commit('SET_TOASTS', createToasts(response.toasts))

    commit('SET_LOADING', false)
  }
}
