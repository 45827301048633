export default [
  {
    path: 'international',
    component: () => import('@/views/International/International.vue'),
    children: [
      {
        path: 'positions',
        name: 'InternationalPositions',
        component: () => import('@/views/International/InternationalPositions.vue'),
        redirect: { name: 'InternationalPositionsList' },
        children: [
          {
            path: 'list',
            name: 'InternationalPositionsList',
            component: () => import(
              '@/views/International/InternationalPositions/InternationalPositionsList.vue'
            )
          },
          {
            path: 'recommended',
            name: 'InternationalPositionsRecommended',
            component: () => import(
              '@/views/International/InternationalPositions/InternationalPositionsRecommended.vue'
            )
          },
          {
            path: 'applied',
            name: 'InternationalPositionsApplied',
            component: () => import(
              '@/views/International/InternationalPositions/InternationalPositionsApplied.vue'
            )
          }
        ]
      },
      {
        path: 'positions/:id',
        name: 'InternationalPosition',
        component: () => import('@/views/International/InternationalPosition.vue')
      },
      {
        path: 'positions/:id/apply',
        name: 'InternationalPositionApply',
        component: () => import(
          '@/views/International/InternationalPositions/InternationalPositionsApply.vue'
        )
      }
    ]
  }
]
