export default {
  SET_PARSED_LINKEDIN (state, parsedLinkedin) {
    state.name = parsedLinkedin.fullName
    state.photoUrl = parsedLinkedin.photoUrl
    state.rawPhotoUrl = parsedLinkedin.rawPhotoUrl
    state.location = parsedLinkedin.location
    state.educations = parsedLinkedin.educations
    state.experiences = parsedLinkedin.experiences
  },

  CLEAR_LINKEDIN_EDUCATIONS (state) {
    state.educations = []
  }
}
