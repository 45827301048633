import { get, post } from '@/lib/request'
import { createApplicant } from '@/models/latam-applicant'

export default {
  async getApplicant (_, id) {
    const { response } = await get(`/latam/applicants/${id}`)

    return createApplicant(response)
  },

  async acceptInvite (_, id) {
    return post(`/invites/${id}/accept`)
  },

  async rejectInvite (_, payload) {
    return post(`/invites/${payload.id}/reject`, payload)
  },

  async rejectApplicant (_, payload) {
    return post(`/latam/applicants/${payload.id}/reject`, payload)
  },

  async cancelInterview(_, payload) {
    return post(`/latam/applicants/${payload.id}/interviews/${payload.interviewId}/cancel`, payload)
  },

  async rescheduleInterview(_, payload) {
    return post(`/latam/applicants/${payload.id}/interviews/${payload.interviewId}/reschedule`, payload)
  },

  async getSlots(_, payload) {
    return get(`/latam/applicants/${payload.id}/interviews/${payload.interviewId}/slots`, payload)
  },

  async showInterview(_, payload) {
    return get(`/latam/applicants/${payload.id}/interviews/${payload.interviewId}`)
  },

  async scheduleInterview(_, payload) {
    return post(
      `/latam/applicants/${payload.id}/interviews/${payload.interviewId}/schedule`,
      payload
    )
  },

  async indexApplicants (_, payload) {
    return get('/latam/applicants', payload)
  },

  async counterApplicantsState (_, payload) {
    return get('/latam/applicants/count_by_state', payload)
  }
}
