import i18n from '@/i18n'
import Cards from '@/constants/pending-actions/cards'

const CARDS_TRANSLATIONS = {
  internationalTechAssessment: () => ({
    content: 'pendingActions.cards.internationalTechAssessment.content',
    contentEmphasis: ''
  }),
  internationalAssessmentUnbundled: () => ({
    content: 'pendingActions.cards.internationalAssessmentUnbundled.content',
    contentEmphasis: ''
  }),
  jobSkills: () => ({
    content: 'pendingActions.cards.jobSkills.content',
    contentEmphasis: i18n.global.t('pendingActions.cards.jobSkills.contentEmphasis')
  }),
  portfolio: () => ({
    content: 'pendingActions.cards.portfolio.content',
    contentEmphasis: i18n.global.t('pendingActions.cards.portfolio.contentEmphasis')
  }),
  profilePhoto: () => ({
    content: 'pendingActions.cards.profilePhoto.content',
    contentEmphasis: i18n.global.t('pendingActions.cards.profilePhoto.contentEmphasis')
  }),
  screening: () => ({
    content: 'pendingActions.cards.screening.content',
    contentEmphasis: i18n.global.t('pendingActions.cards.screening.contentEmphasis')
  }),
  secondarySkills: () => ({
    content: 'pendingActions.cards.secondarySkills.content',
    contentEmphasis: i18n.global.t('pendingActions.cards.secondarySkills.contentEmphasis')
  }),
  secondarySkillsExperienceTime: () => ({
    content: 'pendingActions.cards.secondarySkillsExperienceTime.content',
    contentEmphasis: i18n.global.t('pendingActions.cards.secondarySkillsExperienceTime.contentEmphasis')
  })
}

export const createCard = card => {
  const currentCard = Cards[card.id]

  if (!currentCard) return

  const cardContent = CARDS_TRANSLATIONS[currentCard.path]
  const translation = cardContent && cardContent(card)

  return {
    id: card.id,
    completed: card.completed,
    content: translation.content,
    contentEmphasis: translation.contentEmphasis,
    route: currentCard.route(card),
    icon: currentCard.icon
  }
}

export const createCards = cards => cards.map(createCard).filter(card => card)
