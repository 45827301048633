export default [
  {
    path: 'assessments',
    component: () => import('@/views/Assessments/Assessments.vue'),
    children: [
      {
        path: '',
        name: 'AssessmentsList',
        component: () => import('@/views/Assessments/AssessmentsList.vue')
      },
      {
        path: ':slug',
        name: 'AssessmentRedirect',
        component: () => import('@/views/Assessments/AssessmentRedirect.vue'),
        props: true
      }
    ]
  }
]
