import externalUrls from '../external-urls'

export default {
  marketplace_uninvitable: {
    path: 'marketplaceUninvitable',
    icon: 'exclamation-triangle',
    externalUrl: externalUrls.faq
  },
  marketplace_visibility: {
    path: 'marketplaceVisibility',
    icon: 'check-circle',
    dispatch: ['candidate/setIsChoosingToBeVisible', true]
  }
}
