export default {
  CREATE_ALERT_NOTIFICATION (state, notification) {
    state.alerts.push({
      title: notification.title,
      message: notification.message,
      variant: notification.variant
    })
  },

  DESTROY_ALERT_NOTIFICATION (state, notificationIndex) {
    state.alerts.splice(notificationIndex, 1)
  }
}
