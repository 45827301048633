import { createSkill } from './skill'

export const createJobExperience = (jobExperience) => ({
  id: jobExperience.id,
  startDate: jobExperience.startDate && new Date(jobExperience.startDate),
  endDate: jobExperience.endDate && new Date(jobExperience.endDate),
  jobRole: jobExperience.jobRole,
  companyName: jobExperience.companyName,
  companyLinkedinUrl: jobExperience.companyLinkedinUrl,
  companyIndustry: jobExperience.companyIndustry,
  skills: (jobExperience.skills || []).map(createSkill),
  description: jobExperience.description
})
