export default [
  {
    path: 'dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    children: [
      {
        path: '',
        name: 'DashboardHome',
        component: () => import('@/views/Dashboard/DashboardHome.vue')
      },
      {
        path: 'marketplace/redirect',
        name: 'DashboardMarketplaceRedirect',
        component: () => import('@/views/Dashboard/DashboardMarketplaceRedirect.vue')
      }
    ]
  }
]
