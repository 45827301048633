export default [
  {
    path: 'edit',
    component: () => import('@/views/Profile/ProfileEdit.vue'),
    children: [
      {
        path: 'latam-salary',
        name: 'ProfileEditLatamSalary',
        component: () => import('@/views/Profile/Edit/ProfileEditLatamSalary.vue')
      },
      {
        path: 'current-salary',
        name: 'ProfileEditCurrentSalary',
        component: () => import('@/views/Profile/Edit/ProfileEditCurrentSalary.vue')
      },
      {
        path: 'diversities',
        name: 'ProfileEditDiversities',
        component: () => import('@/views/Profile/Edit/ProfileEditDiversities.vue')
      },
      {
        path: 'english-level',
        name: 'ProfileEditEnglishLevel',
        component: () => import('@/views/Profile/Edit/ProfileEditEnglishLevel.vue')
      },
      {
        path: 'experience-time',
        redirect: { name: 'ProfileEditCareer' }
      },
      {
        path: 'location',
        name: 'ProfileEditLocation',
        component: () => import('@/views/Profile/Edit/ProfileEditLocation.vue')
      },
      {
        path: 'linkedin',
        name: 'ProfileEditLinkedin',
        component: () => import('@/views/Profile/Edit/ProfileEditLinkedin.vue')
      },
      {
        path: 'portfolios',
        name: 'ProfileEditPortfolios',
        component: () => import('@/views/Profile/Edit/ProfileEditPortfolios.vue')
      },
      {
        path: 'name',
        name: 'ProfileEditName',
        component: () => import('@/views/Profile/Edit/ProfileEditName.vue')
      },
      {
        path: 'phone',
        name: 'ProfileEditPhone',
        component: () => import('@/views/Profile/Edit/ProfileEditPhone.vue')
      },
      {
        path: 'photo',
        name: 'ProfileEditPhoto',
        component: () => import('@/views/Profile/Edit/ProfileEditPhoto.vue')
      },
      {
        path: 'skills',
        name: 'ProfileEditSkills',
        component: () => import('@/views/Profile/Edit/ProfileEditSkills.vue')
      },
      {
        path: 'primary-skills',
        redirect: { name: 'ProfileEditSkills' }
      },
      {
        path: 'secondary-skills',
        redirect: { name: 'ProfileEditSkills' }
      },
      {
        path: 'privacies',
        name: 'ProfileEditPrivacies',
        component: () => import('@/views/Profile/Edit/ProfileEditPrivacies.vue')
      },
      {
        path: 'career',
        name: 'ProfileEditCareer',
        component: () => import('@/views/Profile/Edit/ProfileEditCareer.vue')
      },
      {
        path: 'language',
        name: 'ProfileEditLanguage',
        component: () => import('@/views/Profile/Edit/ProfileEditLanguage.vue')
      },
      {
        path: 'delete-account',
        name: 'ProfileDeleteAccount',
        component: () => import('@/views/Profile/Edit/ProfileDeleteAccount.vue')
      }
    ]
  }
]
