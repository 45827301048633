import { createI18n } from 'vue-i18n'
import getBrowserLocale from './util/get-browser-locale'
import supportedLocales from './util/supported-locales'
import dateTimeFormats from '@/i18n/locales/date-time-formats.json'
import numberFormats from '@/i18n/locales/number-formats.json'
import rollbar from '@/support/rollbar'

function getStartingLocale () {
  const browserLocale = getBrowserLocale()

  if (Object.keys(supportedLocales).includes(getBrowserLocale())) {
    return browserLocale
  } else {
    return process.env.VUE_APP_I18N_LOCALE || 'en-US'
  }
}

const i18n = createI18n({
  legacy: false,
  locale: getStartingLocale(),
  globalInjection: true,
  datetimeFormats: dateTimeFormats,
  numberFormats,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  messages: {
    'en-US': require('./locales/en-US.json'),
    'es-MX': require('./locales/es-MX.json'),
    'pt-BR': require('./locales/pt-BR.json')
  },
  missing (locale, key, _, values) {
    rollbar?.error(`Translation missing (${locale}): ${key} with values: ${JSON.stringify(values)}`)
  }
})

export default i18n
