
export default {
  SET_FOCUSES (state, focuses) {
    const ids = [...new Set(focuses.map(focus => focus.career.id))]

    const values = []

    ids.forEach(id => {
      const filteredFocuses = focuses.filter(focus => focus.career.id === id)

      values.push(filteredFocuses)
    })

    state.all = values
  },

  SET_LOADING (state, value) {
    state.loading = value
  }
}
