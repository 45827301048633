import { post } from '@/lib/request'

export default {
  async acceptInvite (_, id) {
    const { response } = await post(`/international/invites/${id}/accept`)

    return response
  },

  async rejectInvite (_, params) {
    const { response } = await post(`/international/invites/${params.id}/reject`, params)

    return response
  }
}
