import { createCandidate } from '@/models/candidate'

export default {
  current: createCandidate({}),
  eligibleForInternational: null,
  jobExperiences: undefined,
  educations: undefined,
  updateQueue: [],
  isChoosingToBeVisible: false,
  latamPendingActions: 0
}
