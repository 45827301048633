export default {
  // FIXME: Rename this getter, it has the same name as getPosition action
  getPosition: (state) => (id) => {
    return state.positions.current.find(position => position.id === id)
  },
  hasPositions (state) { return state.positions.count > 0 },
  loadedLastPage (state) { return state.positions.currentPage === state.positions.totalPages },
  loadedAppliedLastPage (state) {
    return state.appliedPositions.currentPage === state.appliedPositions.totalPages
  },
  firstAppliedLoad (state) {
    return state.appliedPositions.loading && !state.appliedPositions.current.length
  },
  firstRecommendedLoad (state) {
    return state.recommendedPositions.loading && !state.recommendedPositions.current.length
  },
  loadedRecommendedLastPage (state) {
    return state.recommendedPositions.currentPage === state.recommendedPositions.totalPages
  },
  isRecommendedPosition: (state) => (id) => {
    const recommendedPositionsId = state.recommendedPositions.current.map(
      (recommendedPosition) => recommendedPosition.id
    )
    return recommendedPositionsId.includes(id)
  }
}
