export default [
  {
    path: 'job-experiences',
    component: () => import(
      '@/views/Profile/Edit/ProfileProfessionalExperiences/ProfileProfessionalExperiences.vue'
    ),
    children: [
      {
        path: '',
        name: 'ProfileProfessionalExperiences',
        component: () => import(
          '@/views/Profile/Edit/ProfileProfessionalExperiences/ProfileProfessionalExperiencesList'
        )
      },
      {
        path: 'create',
        name: 'ProfileCreateProfessionalExperience',
        component: () => import(
          '@/views/Profile/Edit/ProfileProfessionalExperiences/ProfileEditProfessionalExperience'
        ),
        meta: { routeNameRedirect: 'ProfileProfessionalExperiences' }
      },
      {
        path: 'edit/:jobExperienceIndex',
        name: 'ProfileEditProfessionalExperience',
        component: () => import(
          '@/views/Profile/Edit/ProfileProfessionalExperiences/ProfileEditProfessionalExperience'
        ),
        meta: { routeNameRedirect: 'ProfileProfessionalExperiences' }
      }
    ]
  }
]
