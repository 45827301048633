export default [
  {
    path: 'educations',
    component: () => import('@/views/Profile/Edit/ProfileEducations/ProfileEducations.vue'),
    children: [
      {
        path: '',
        name: 'ProfileEducations',
        component: () => import('@/views/Profile/Edit/ProfileEducations/ProfileEducationsList.vue')
      },
      {
        path: 'create',
        name: 'ProfileCreateEducation',
        component: () => import('@/views/Profile/Edit/ProfileEducations/ProfileEditEducation.vue'),
        meta: { routeNameRedirect: 'ProfileEducations' }
      },
      {
        path: 'edit/:educationIndex',
        name: 'ProfileEditEducation',
        component: () => import('@/views/Profile/Edit/ProfileEducations/ProfileEditEducation.vue'),
        meta: { routeNameRedirect: 'ProfileEducations' }
      }
    ]
  }
]
