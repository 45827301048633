import { post } from '@/lib/request'

export default {
  async acceptInvite (_, id) {
    return post(`invites/${id}/accept`)
  },

  async rejectInvite (_, payload) {
    return post(`invites/${payload.id}/reject`, payload)
  }
}
