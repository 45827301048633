import { del, get, patch, post } from '@/lib/request'
import Rollbar from '@/support/rollbar'

export default {
  async getCurrent ({ commit }, params = {}) {
    const { response, status } = await get('/candidate', { origin: params.origin })

    commit('SET_CURRENT', { candidate: response, marketplace: response.marketplace })

    return {
      candidate: response,
      status
    }
  },

  async saveCandidate ({ state, dispatch, commit }, attributes) {
    commit('PUSH_UPDATE_TO_QUEUE', attributes)

    // Parallel requests on Global Candidate may be avoided because they trigger server
    // errors during process. So, instead of requesting directly, saveCandidate fill a queue
    // and request to the server in a chain.
    if (state.updateQueue.length === 1) { await dispatch('performCandidateUpdate') }
  },

  async performCandidateUpdate ({ commit, state, dispatch, getters }) {
    if (state.updateQueue.length === 0) { return }

    // All updates will reduce to a single POST on the API.
    const updatesOnQueue = [...state.updateQueue]
    const attributes = updatesOnQueue.reduce((memo, update) => ({
      ...memo, ...update, marketplace: { ...memo.marketplace, ...update.marketplace }
    }), { marketplace: {} })

    const buildPatchAttributes = () => {
      const current = state.current

      return {
        id: current.id,
        name: current.name,
        englishLevel: current.englishLevel,
        phone: current.phone,
        comunicationPreferences: current.comunicationPreferences,
        pretensionSalary: current.pretensionSalary,
        pretensionCurrency: current.pretensionCurrency,
        pretensionPeriodicity: current.pretensionPeriodicity,
        experienceTime: current.experienceTime,
        skills: current.skills,
        focusId: current.focus?.id,
        experiences: current.jobExperiences,
        educations: current.educations,
        salaries: [
          getters.internationalPretensionSalary,
          getters.latamPretensionSalary,
          getters.currentSalary
        ].filter(salary => !!salary),
        disabilities: current.disabilities,
        diversities: current.diversities,
        portfolios: current.portfolios,
        ...attributes,
        marketplace: {
          ...current.marketplace,
          ...attributes.marketplace
        }
      }
    }

    const payload = buildPatchAttributes()

    try {
      const { response } = await patch('/candidate', payload)

      commit('SET_CURRENT', { candidate: response, marketplace: response.marketplace })
    }
    catch (e) {
      Rollbar.error(e)
    }
    finally {
      commit('REMOVE_UPDATES_FROM_QUEUE', updatesOnQueue.length)

      // If another saveCandidate is called during the request, we need to perform
      // candidate update until queue are clear.
      await dispatch('performCandidateUpdate')
    }
  },

  async getAbAlternative (_, experimentName) {
    const { response, status } = await get('/ab_tests/ab_alternative', { experimentName })

    return {
      alternative: response.alternative,
      status
    }
  },

  finishAbTest (_, experimentName) {
    return post('/ab_tests/finish', { experimentName })
  },

  async getInternationalEligibility ({ commit }) {
    const { response } = await get('/international/eligibility')

    commit('SET_ELIGIBLE_FOR_INTERNATIONAL', response.eligible)
  },

  async toggleVisibleOnMarketplace ({ dispatch }) {
    await post('/candidate/toggle_visibility')

    dispatch('getCurrent')
  },

  setJobExperiences ({ commit }, jobExperiences) {
    commit('SET_JOB_EXPERIENCES', jobExperiences)
  },

  addJobExperience ({ commit }, jobExperience) {
    commit('ADD_JOB_EXPERIENCE', jobExperience)
  },

  updateJobExperience ({ commit }, { index, jobExperience }) {
    commit('UPDATE_JOB_EXPERIENCE', { index, jobExperience })
  },

  removeJobExperience ({ commit, state }, index) {
    commit(
      'SET_JOB_EXPERIENCES',
      state.jobExperiences.filter((_, jobExperienceIndex) => jobExperienceIndex !== index)
    )
  },

  setEducations ({ commit }, educations) {
    commit('SET_EDUCATIONS', educations)
  },

  addEducation ({ commit }, education) {
    commit('ADD_EDUCATION', education)
  },

  updateEducation ({ commit }, { index, education }) {
    commit('UPDATE_EDUCATION', { index, education })
  },

  upsertSalary ({ commit, state }, salary) {
    const index = state.current.salaries.findIndex((currentSalary) => {
      return (
        currentSalary.kind === salary.kind
      )
    })

    if (index >= 0) {
      commit('UPDATE_SALARY', { index, salary })
    } else {
      commit('ADD_SALARY', salary)
    }
  },

  async uploadPhoto (_, photo) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()

    formData.set('photo', photo)

    const { status } = await patch('/candidates/photos', formData, options)

    return { status }
  },

  async uploadLinkedinPhoto () {
    await patch('/candidates/photos', { linkedin: true })
  },

  async removePhoto () {
    const { status } = await del('/candidates/photos')

    return { status }
  },

  setIsChoosingToBeVisible ({ commit }, isChoosing) {
    commit('SET_IS_CHOOSING_TO_BE_VISIBLE', isChoosing)
  },

  async parseCv ({ commit }, file) {
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const formData = new FormData()

    formData.set('file', file)

    const { response } = await post('/cv_parse', formData, options)

    if (response.experiences.length) {
      commit('SET_JOB_EXPERIENCES', response.experiences)
    }

    if (response.educations.length) {
      commit('SET_EDUCATIONS', response.educations)
    }
  },

  async deleteAccount (_, { reason, otherReason }) {
    await del('/candidate', { reason, otherReason })
  },

  async saveSignupWelcome ({ commit, state }, params) {
    const { response } = await patch('/candidates/signup/welcome', params)

    commit('SET_CURRENT', { candidate: response, marketplace: state.current.marketplace })
  },

  async saveSignupPersonalInfo ({ commit, state }, params) {
    const { response } = await patch('/candidates/signup/personal_info', params)

    commit('SET_CURRENT', { candidate: response, marketplace: state.current.marketplace })
  },

  async saveSignupExperiences ({ commit, state }, params) {
    const { response } = await patch('/candidates/signup/experiences', params)

    commit('SET_CURRENT', { candidate: response, marketplace: state.current.marketplace })
  },

  async saveSignupSkills ({ commit, state }, params) {
    const { response } = await patch('/candidates/signup/skills', params)

    commit('SET_CURRENT', { candidate: response, marketplace: state.current.marketplace })
  },

  async saveSignupOtherInfo ({ commit }, params) {
    const { response } = await patch('/candidates/signup/other_info', params)

    commit('SET_CURRENT', { candidate: response, marketplace: response.marketplace })
  },

  async toggleVisibility () {
    const { status } = await post('/candidates/toggle_visibility')

    return { status }
  },

  async getLatamPendingActions ({ commit }) {
    const { response } = await get('/latam/candidates/pending_actions')

    commit('SET_LATAM_PENDING_ACTIONS', response.meta.pendingActions)

    return response.meta.pendingActions
  },
}
