import { createPosition } from '@/models/international/position.js'

export const createApplication = (application) => ({
  id: application.id,
  status: application.status,
  globalCandidateId: application.globalCandidateId,
  applicationQuestionAnswers: application.applicationQuestionAnswers,
  interviews: application.interviews,
  position: createPosition(application.position),
  invite: application.invite
})
