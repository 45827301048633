import keycloakAdapter from '@revelotech/frontend-core/keycloak'

export let keycloak
export const initKeycloak = async () => {
  keycloak = await keycloakAdapter({
    url: process.env.VUE_APP_SSO_HOST_URL,
    realm: 'candidates',
    clientId: 'web'
  }, {
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`
  })

  return keycloak
}
