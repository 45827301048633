import { authorize } from '@/router/middlewares/keycloak'

import assessments from './assessments'
import dashboard from './dashboard'
import international from './international'
import internationalApplication from './international-application'
import latamApplicant from './latam-applicant'
import profile from './profile'
import signup from './signup'
import applicants from './applicants'
import applications from './applications'

export default ({
  beforeEnter: [authorize],
  children: [
    { path: '/', redirect: (to) => ({ name: 'DashboardHome', query: to.query }) },
    ...assessments,
    ...dashboard,
    ...international,
    ...latamApplicant,
    ...internationalApplication,
    ...profile,
    ...signup,
    ...applicants,
    ...applications,
    { path: ':path?', redirect: (to) => ({ name: 'DashboardHome', query: to.query }) }
  ]
})
