import { get } from '@/lib/request'

export default {
  async getAssessments ({ commit }) {
    commit('SET_LOADING', true)

    const { response } = await get('/assessments')

    commit('SET_ASSESSMENTS', response)

    commit('SET_LOADING', false)
  }
}
