const localizedUrl = (url) => (locale) => url[locale] || url.default

const faq = {
  default: 'https://wa.me/551149337732',
  'pt-BR': 'https://wa.me/551149337732',
  'es-MX': 'https://wa.me/551149337732'
}

const homeInternational = 'https://revelo.com/'

const noOpportunitiesReason = {
  default: 'https://revelo.zendesk.com/hc/en-us/articles/' +
    '4416614868365-Why-aren-t-there-opportunuties-for-me-',
  'pt-BR': 'https://revelo.zendesk.com/hc/pt-br/articles/' +
    '4414699522317-Por-que-n%C3%A3o-h%C3%A1-vagas-para-mim-',
  'es-MX': 'https://revelo.zendesk.com/hc/es-419/articles/' +
    '4417296993421--Por-qué-no-hay-oportunidades-para-mí-'
}

const assessmentsApp = 'https://app.assessments.revelo.com'

const appProfileViewDeepLink = 'revelo://profile'
const appStoreUrl = 'https://revelo.app.link/v5kTgccBDtb'
const googlePlayUrl = 'https://revelo.app.link/httpzMABDtb'

const supportEmail = 'support@revelo.com'

export default {
  assessmentsApp,
  appProfileViewDeepLink,
  appStoreUrl,
  faq: localizedUrl(faq),
  googlePlayUrl,
  homeInternational,
  noOpportunitiesReason: localizedUrl(noOpportunitiesReason),
  supportEmail
}
