import { createRouter, createWebHistory } from 'vue-router'

import privateRoutes from './private'
import publicRoutes from './public'

const routes = [
  {
    path: '/',
    component: () => import('@/views/Private.vue'),
    ...privateRoutes
  },
  {
    path: '/public',
    component: () => import('@/views/Public.vue'),
    ...publicRoutes
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior() { return { left: 0, top: 0 } }
})

router.beforeEach((to, _, next) => {
  if (!to.hash) return next()

  return window.location.replace(to.hash.split('#')[1])
})

export default router
